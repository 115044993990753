import { styled } from '@mui/material/styles';
import { ListClasses } from 'react-admin';

import { ResourceList } from '@Widgets/ResourceList/ResourceList';

export const StyledResourceList = styled(ResourceList)`
  .${ListClasses.main} > .MuiToolbar-root {
    align-items: center;
  }
` as typeof ResourceList;
