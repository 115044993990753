import { Filter, ListColumn } from '@Widgets/ResourceList/interface';

import { Predict } from './interface';
import {
  WeekSalesForecast,
  WeekSalesForecastHeaderColumn,
} from './components/columns/WeekSalesForecast';

export const CALCULATION_LAST_JOB_ID_KEY =
  'replenishment.calculation.lastJobId';

export const CALCULATION_POLLING_TIME = 3000;

export const LIST_COLUMNS: ListColumn<Predict>[] = [
  {
    source: 'store_name',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.store',
  },
  {
    source: 'sku',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.sku',
  },
  {
    source: 'product_name',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.productName',
  },
  {
    source: 'supplier_name',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.supplier',
  },
  {
    source: 'cat1',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.category',
  },
  {
    source: 'company_name',
    type: 'text',
    label: 'Company',
  },
  {
    source: 'packages',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.packages',
  },
  {
    source: 'final_cluster',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.finalCluster',
  },
  // { source: 'cost_per_item', type: 'text', label: 'replenishment.pages.calculate.labels.costPerItem' },
  // { source: 'cost_per_package', type: 'text', label: 'replenishment.pages.calculate.labels.costPerPackage' },
  {
    source: 'shelf_life',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.shelfLife',
  },
  {
    source: 'matrix',
    type: 'boolean',
    label: 'replenishment.pages.calculate.labels.productMatrix',
  },
  {
    source: 'sales_llw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.salesLLW',
  },
  {
    source: 'sales_lw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.salesLW',
  },
  {
    source: 'sales_tw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.salesTW',
  },
  {
    source: 'corrected_sales_llw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.correctedSalesLLW',
  },
  {
    source: 'corrected_sales_lw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.correctedSalesLW',
  },
  {
    source: 'corrected_sales_tw',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.correctedSalesTW',
  },
  {
    source: 'week_sales_forecast',
    type: 'custom',
    label: 'replenishment.pages.calculate.labels.weeklySalesForecast',
    labelElement: <WeekSalesForecastHeaderColumn />,
    customComponent: <WeekSalesForecast />,
  },
  // { source: 'write_off_LLW', type: 'text', label: 'replenishment.pages.calculate.labels.WriteOffLLW' },
  // { source: 'write_off_LW', type: 'text', label: 'replenishment.pages.calculate.labels.WriteOffLW' },
  // { source: 'write_off_TW', type: 'text', label: 'replenishment.pages.calculate.labels.WriteOffTW' },
  // { source: 'delivery_ACT_LLW', type: 'text', label: 'replenishment.pages.calculate.labels.DeliveryACT_LLW' },
  // { source: 'delivery_ACT_LW', type: 'text', label: 'replenishment.pages.calculate.labels.DeliveryACT_LW' },
  // { source: 'delivery_ACT_TW', type: 'text', label: 'replenishment.pages.calculate.labels.DeliveryACT_TW' },
  {
    source: 'stock_act',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.stockAct',
  },
  {
    source: 'delivery_next',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.futureDeliveries',
  },
  {
    source: 'sales_increase',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.salesIncreasing',
  },
  {
    source: 'sales_forecast_agg',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.salesForecastAgg',
  },
  {
    source: 'wo_forecast_agg',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.woForecastAgg',
  },
  {
    source: 'nearest_delivery',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.nearestDelivery',
  },
  {
    source: 'closest_delivery_date',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.closestDeliveryDate',
  },
  {
    source: 'next_closest_delivery_date',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.nextClosestDeliveryDate',
  },
  {
    source: 'insurance_stock',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.insuranceStock',
  },
  {
    source: 'cluster_rule_package',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.clusterRule',
  },
  {
    source: 'cluster_zero_stock',
    type: 'boolean',
    label: 'replenishment.pages.calculate.labels.zeroStockRule',
  },
  {
    source: 'final_order',
    type: 'text',
    label: 'replenishment.pages.calculate.labels.finalOrder',
  },
];

const ALERTS_CHOICES: SelectOption[] = [
  { name: 'True', id: 'true' },
  { name: 'False', id: 'false' },
];

export const FILTERS: Filter[] = [
  { source: 'store', type: 'search' },
  { source: 'supplier', type: 'search' },
  { source: 'category', type: 'search' },
  { source: 'cluster', type: 'search' },
  { source: 'company', type: 'search' },
  {
    source: 'alerts',
    type: 'choose',
    label: 'Alerts',
    choices: ALERTS_CHOICES,
    emptyText: '---',
  },
  {
    source: 'matrix',
    type: 'choose',
    label: 'Matrix',
    choices: ALERTS_CHOICES,
    emptyText: '---',
  },
];
