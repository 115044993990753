import { ReactNode } from 'react';
import { Validator } from 'react-admin';

import { LocalFile, RejectFile, DropzoneProps } from '@UI/Dropzone/interfaces';
import { FieldError } from 'react-hook-form';

export enum DropzoneFileType {
  IMAGE = 'image',
  FILE = 'file',
}

export interface ResourceDropzoneProps
  extends Pick<
    DropzoneProps,
    | 'label'
    | 'validateFileRemoval'
    | 'multiple'
    | 'aspectRatio'
    | 'maxSize'
    | 'allowedTypes'
    | 'maxFileRows'
    | 'className'
  > {
  source: string;
  onDropAccepted?: (validFiles: LocalFile[]) => void;
  onDropRejected?: (rejectedFiles: RejectFile[]) => void;
  validate?: Validator | Validator[];
  required?: boolean;
  type?: DropzoneFileType;
  children?: ReactNode;
  error?: FieldError;
}
