import {
  DateInput,
  DateTimeInput,
  FileField,
  FormDataConsumer,
  number,
  required,
  TextInput,
  TimeInput,
  Validator,
} from 'react-admin';
import { Link, Box } from '@mui/material';

import { get, isInteger, minNum } from '@Helpers';
import {
  ResourceColorInput,
  ResourceDropzone,
  ResourceInputNumber,
  ResourceSwitcher,
} from '@UI';
import { DropzoneFileType } from '@UI/ResourceUI/ResourceDropzone/interfaces';

import { AdditionalListPropertiesProps } from '../interface';

const validate = (type: CorePropertyType, code: string) => {
  if (type === 'toggle') return undefined;

  const validationList: Validator[] = [required()];

  if (type === 'number') {
    validationList.push(number(), minNum(0));
  }

  if (code === 'items_per_outer_retail_pack') {
    validationList.push(isInteger());
  }

  return validationList;
};

export const SingleProperty = ({
  source,
  label,
  type,
  code,
}: Omit<AdditionalListPropertiesProps, 'choices'>) => {
  const options = {
    name: source,
    source,
    label,
    translate: 'no' as const,
    fullWidth: true,
    validate: validate(type, code),
  };

  switch (type) {
    case 'text':
      return <TextInput {...options} type="text" />;
    case 'textarea':
      return <TextInput multiline rows={3} {...options} />;
    case 'toggle':
      return <ResourceSwitcher labelKey={options.label} {...options} />;
    case 'badge':
      return <ResourceSwitcher labelKey={options.label} {...options} />;
    case 'dateTime':
      return <DateTimeInput {...options} />;
    case 'time':
      return <TimeInput {...options} />;
    case 'date':
      return <DateInput {...options} />;
    case 'color':
      return <ResourceColorInput labelKey={label} {...options} />;
    case 'file':
      return (
        <ResourceDropzone type={DropzoneFileType.FILE} {...options}>
          <FormDataConsumer>
            {({ formData }) => {
              const data = get(formData, source);

              if (typeof data === 'string') {
                return (
                  <Box sx={{ px: 2, py: 1 }}>
                    <Link href={data} target="_blank" download variant="body2">
                      {data}
                    </Link>
                  </Box>
                );
              }

              return (
                <FileField
                  download
                  target="_blank"
                  source="url"
                  title="title"
                  sx={{ px: 2, py: 1 }}
                />
              );
            }}
          </FormDataConsumer>
        </ResourceDropzone>
      );
    case 'image':
      return <ResourceDropzone type={DropzoneFileType.IMAGE} {...options} />;
    case 'number':
      return <ResourceInputNumber {...options} />;
    default:
      return null;
  }
};
