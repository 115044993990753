import { AllowedType } from '@UI/Dropzone/interfaces';

import { DropzoneFileType } from './interfaces';

export const DropzoneTypeAcceptMap: Record<DropzoneFileType, AllowedType[]> = {
  [DropzoneFileType.FILE]: ['.xlsx', '.xls', '.csv'],
  [DropzoneFileType.IMAGE]: ['.png', '.jpg', '.jpeg'],
};

export const DropzoneTypedLabel: Record<
  DropzoneFileType,
  { single: string; several: string }
> = {
  [DropzoneFileType.FILE]: {
    single: 'ra.input.file.upload_single',
    several: 'ra.input.file.upload_several',
  },
  [DropzoneFileType.IMAGE]: {
    single: 'ra.input.image.upload_single',
    several: 'ra.input.image.upload_several',
  },
};
