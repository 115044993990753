import { useEffect } from 'react';
import { useTranslate } from 'react-admin';
import { Paper } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';

import { FilePreviewProps } from './interfaces';
import { DropzoneClassNames } from './styled';

const FilePreview = (props: FilePreviewProps) => {
  const { children, file, onRemove } = props;

  const translate = useTranslate();

  useEffect(
    () => () => {
      if (!file.isRemote) {
        URL.revokeObjectURL(file.url);
      }
    },
    [file]
  );

  return (
    <Paper className={DropzoneClassNames.previewItem}>
      <IconButton
        onClick={onRemove}
        aria-label={translate('ra.action.delete')}
        title={translate('ra.action.delete')}
        size="small"
      >
        <RemoveCircle />
      </IconButton>
      {children}
    </Paper>
  );
};

export default FilePreview;
