import { useTranslate } from 'react-admin';

import { HighlightedColumn } from './styled';

const WeekSalesForecastHeaderColumn = () => {
  const translate = useTranslate();

  return (
    <HighlightedColumn>
      {translate('replenishment.pages.calculate.labels.weeklySalesForecast')}
    </HighlightedColumn>
  );
};

export default WeekSalesForecastHeaderColumn;
