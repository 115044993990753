import { RequestAPI } from '@RestApi';
import { EXCLUDE_PACKAGE_API_URL } from '@Plugins/WMSPicking/resources/ClickAndCollect/constants';

export interface ExcludePackagesRequestData {
  id: string;
  data: { bag_types: number[] };
}

export const excludePackagesRequest = async ({
  id,
  data,
}: ExcludePackagesRequestData): Promise<{ actual_paid_price: number }> =>
  RequestAPI.post(`${EXCLUDE_PACKAGE_API_URL}/${id}`, data);
