import { useRef, useState } from 'react';
import { useTranslate } from 'react-admin';
import { useController, useFormContext } from 'react-hook-form';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material';

import { RowInfoBlock, ResourceDropzone } from '@UI';
import { DropzoneFileType } from '@UI/ResourceUI/ResourceDropzone/interfaces';
import { accessPermission } from '@Helpers';

import {
  REQUIRED_WITH_IMAGE_DISPLAY_TYPE,
  REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE,
} from '../../constants';

import { ImageDeletionValidateResolver } from './interfaces';
import { validateAspectRatio } from './validators';
import {
  DESKTOP_IMAGE_ASPECT_RATIO,
  MOBILE_IMAGE_ASPECT_RATIO,
  PROMO_BANNER_DISPLAY_TYPE,
} from './constants';

const Images = () => {
  const { field: displayTypeField } = useController<CoreCategory>({
    name: 'displayType',
  });

  const translate = useTranslate();
  const { getValues, setValue, clearErrors } = useFormContext<CoreCategory>();

  const [isImageDeleteConfirmModalOpen, setIsImageDeleteConfirmModalOpen] =
    useState<boolean>(false);

  const imageDeletionValidateResolverRef = useRef<
    ImageDeletionValidateResolver | undefined
  >();

  const handleValidateImageRemoval = () => {
    const { displayType, parentCategory } = getValues();

    if (!accessPermission.company?.categoryCannotBeWithoutImage) {
      return true;
    }

    if (
      Object.values(REQUIRED_WITH_IMAGE_DISPLAY_TYPE).includes(
        `${displayType}`
      ) ||
      (parentCategory &&
        Object.values(REQUIRED_CHILDREN_WITH_IMAGE_DISPLAY_TYPE).includes(
          `${parentCategory.displayType}`
        ))
    ) {
      return new Promise<boolean>((resolve, reject) => {
        imageDeletionValidateResolverRef.current = { resolve, reject };
        setIsImageDeleteConfirmModalOpen(true);
      });
    }

    return true;
  };

  const handleCloseConfirmModal = () => {
    setIsImageDeleteConfirmModalOpen(false);

    if (!imageDeletionValidateResolverRef.current) {
      return;
    }

    imageDeletionValidateResolverRef.current.reject();
    imageDeletionValidateResolverRef.current = undefined;
  };

  const handleConfirmImageDeletion = () => {
    setIsImageDeleteConfirmModalOpen(false);
    clearErrors('active');
    setValue('active', false);

    if (!imageDeletionValidateResolverRef.current) {
      return;
    }

    imageDeletionValidateResolverRef.current.resolve(true);
    imageDeletionValidateResolverRef.current = undefined;
  };

  const handleDropAccepted = () => {
    clearErrors('active');
  };

  return (
    <>
      {!accessPermission.company?.isAdvancedCategory ||
      displayTypeField.value !== PROMO_BANNER_DISPLAY_TYPE ? (
        <>
          <ResourceDropzone
            label="catalogue.pages.categories.labels.previewImage"
            source="previewImage.url"
            onDropAccepted={handleDropAccepted}
            validateFileRemoval={handleValidateImageRemoval}
            type={DropzoneFileType.IMAGE}
          />
        </>
      ) : (
        <RowInfoBlock asColumn={false} flexWrap="nowrap" fullWidth gap={2}>
          <ResourceDropzone
            validateFileRemoval={handleValidateImageRemoval}
            label="catalogue.pages.categories.labels.desktopImage"
            source="previewImage.url"
            validate={validateAspectRatio(DESKTOP_IMAGE_ASPECT_RATIO)}
            aspectRatio="51:7"
            onDropAccepted={handleDropAccepted}
            type={DropzoneFileType.IMAGE}
          />
          <ResourceDropzone
            validateFileRemoval={handleValidateImageRemoval}
            label="catalogue.pages.categories.labels.mobileImage"
            source="previewImageThumb.url"
            validate={validateAspectRatio(MOBILE_IMAGE_ASPECT_RATIO)}
            aspectRatio="18:7"
            onDropAccepted={handleDropAccepted}
            type={DropzoneFileType.IMAGE}
          />
        </RowInfoBlock>
      )}

      <Dialog
        open={isImageDeleteConfirmModalOpen}
        onClose={handleCloseConfirmModal}
      >
        <DialogTitle>
          {translate(
            'catalogue.pages.categories.imageDeleteConfirmModal.title'
          )}
        </DialogTitle>
        <DialogContent>
          {translate(
            'catalogue.pages.categories.imageDeleteConfirmModal.content'
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            color="error"
            onClick={handleCloseConfirmModal}
          >
            {translate(
              'catalogue.pages.categories.imageDeleteConfirmModal.buttons.cancel'
            )}
          </Button>
          <Button
            variant="contained"
            color="error"
            onClick={handleConfirmImageDeletion}
          >
            {translate(
              'catalogue.pages.categories.imageDeleteConfirmModal.buttons.delete'
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Images;
