import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';
import { GetListResult, GetListParams, DeleteResult } from 'react-admin';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';

const categoriesCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.categories
);

const CategoriesController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.categories.resourcePath,
    list: ListPage,
  },
  menuItem: null,
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return categoriesCrud.list(params);
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await categoriesCrud.delete(params);

      return { data: params.previousData };
    },
  },
});

export default CategoriesController;
