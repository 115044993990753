import {
  DeleteWithConfirmButton,
  FormTab,
  required,
  SaveButton,
  TextInput,
  Toolbar,
  useGetList,
  useRecordContext,
  Validator,
} from 'react-admin';
import { useFormContext } from 'react-hook-form';

import {
  Flex,
  ReachTextEditor,
  ResourceAutocompleteInput,
  ResourceInputNumber,
  ResourceMoneyInput,
  ResourceTab,
  ResourceTabbedForm,
  RowInfoBlock,
} from '@UI';
import { useIsMobile } from '@Hooks/useIsMobile';
import { useGroupedCategoryList } from '@Hooks/useGroupedCategoryList';
import { requiredOnActive } from '@Plugins/Catalogue/resources/Offers/utils';
import {
  accessPermission,
  isDiscountPriceSatisfactory,
  isEmptyAfterTrimmed,
  isInteger,
  maxLengthExt,
  minNum,
  pointsToUnits,
} from '@Helpers';

import { ResourceRoutes } from '../../../resourceRoutes';
import { STATUS_CHOICES } from '../share/constants';

import { ProductOffers } from './ProductOffers';
import { DEFAULT_PRODUCT_FORM_VALUES } from './constants';
import { CategorySelect } from './components/CategorySelect';
import { ImagesTab } from './components/ImagesTab';

interface GetResourceAutocompleteInputProps {
  commodityGroupList: SelectOption[];
}

const validateName = [required(), isEmptyAfterTrimmed(), maxLengthExt(255)];
const validatePrice = [required(), minNum(parseFloat(pointsToUnits(1)))];
const validateSorting = [requiredOnActive(), isInteger()];
const validateDiscountPrice = [
  minNum(parseFloat(pointsToUnits(1))),
  isDiscountPriceSatisfactory(),
];
const validateCommodityGroup = [requiredOnActive()];

const GetResourceAutocompleteInput = ({
  commodityGroupList = [],
}: GetResourceAutocompleteInputProps) => {
  const { setValue } = useFormContext();
  const sourceName = 'commodityGroupId';

  const onChange = (val = '') => {
    const selectedOption = commodityGroupList.find(({ id }) => id === val);

    setValue('commodityGroup', selectedOption);
  };

  return (
    <ResourceAutocompleteInput
      name={sourceName}
      source={sourceName}
      choices={commodityGroupList}
      validate={validateCommodityGroup}
      onChange={onChange}
      fullWidth
    />
  );
};

export const ProductForm = () => {
  const record = useRecordContext<CoreProduct>();
  const isMobile = useIsMobile();
  const nestedCategories = useGroupedCategoryList();

  const { data: commodityGroupList = [] } = useGetList(
    ResourceRoutes.commodityGroup.resourcePath
  );

  return (
    <ResourceTabbedForm
      record={record}
      defaultValues={DEFAULT_PRODUCT_FORM_VALUES}
      toolbar={
        accessPermission.checkPermission('Catalogue', 'UD') ? (
          <Toolbar sx={{ justifyContent: 'space-between' }}>
            <Flex gap={2}>
              <SaveButton />
            </Flex>

            <DeleteWithConfirmButton />
          </Toolbar>
        ) : (
          <></>
        )
      }
      mode="all"
      reValidateMode="onChange"
    >
      <FormTab label="catalogue.pages.products.tabs.general">
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              fullWidth
              name="name"
              source="name"
              validate={validateName}
            />

            <RowInfoBlock
              titleKey="catalogue.pages.products.labels.description"
              fullWidth
              sx={{ paddingInline: 0 }}
            >
              <ReachTextEditor name="description" />
            </RowInfoBlock>

            <ResourceMoneyInput
              name="price"
              source="price"
              label="catalogue.pages.products.labels.price"
              validate={validatePrice}
              fullWidth
            />

            <CategorySelect<CoreProduct>
              source="categoryId"
              options={nestedCategories}
              validate={validateCategoryId}
            />

            <ResourceInputNumber
              name="sorting"
              source="sorting"
              label="catalogue.pages.products.labels.sorting"
              isInteger
              validate={validateSorting}
              fullWidth
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <ResourceAutocompleteInput
              name="status"
              source="status"
              optionText="name"
              optionValue="id"
              choices={STATUS_CHOICES}
              translateChoice
              fullWidth
              validate={required()}
            />

            <ResourceMoneyInput
              name="discountPrice"
              source="discountPrice"
              label="catalogue.pages.products.labels.discountPrice"
              validate={validateDiscountPrice}
              fullWidth
            />

            <CategorySelect<CoreProduct>
              label="catalogue.pages.products.labels.additionalCategories"
              source="additionalCategoryIds"
              options={nestedCategories}
              multi
            />

            <GetResourceAutocompleteInput
              commodityGroupList={commodityGroupList}
            />
          </RowInfoBlock>
        </Flex>
      </FormTab>

      <ResourceTab
        subscribedFields={['metadataTitle', 'metadataDescription']}
        label="catalogue.pages.products.tabs.seo"
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock flex={1}>
            <TextInput
              fullWidth
              name="metadataTitle"
              source="metadataTitle"
              label="catalogue.pages.products.labels.metadataTitle"
            />
            <TextInput
              fullWidth
              name="metadataDescription"
              source="metadataDescription"
              multiline
              rows={5}
              label="catalogue.pages.products.labels.metadataDescription"
            />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.products.tabs.additionalLanguages"
        subscribedFields={['description']}
      >
        <Flex asColumn fullWidth>
          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.products.labels.nameAr"
          >
            <TextInput
              fullWidth
              name="nameAr"
              source="nameAr"
              validate={maxLengthExt(255)}
              label="catalogue.pages.products.labels.nameAr"
            />
          </RowInfoBlock>
          <RowInfoBlock
            flex={1}
            titleKey="catalogue.pages.products.labels.descriptionAr"
          >
            <ReachTextEditor name="descriptionAr" isRequired={false} />
          </RowInfoBlock>
        </Flex>
      </ResourceTab>

      <ResourceTab
        label="catalogue.pages.products.tabs.images"
        subscribedFields={['previewImage']}
      >
        <ImagesTab />
      </ResourceTab>

      {/* TODO: uncomment when its needed */}
      {/* <ResourceTab */}
      {/*   label="catalogue.pages.products.tabs.additional" */}
      {/*   subscribedFields={['offerProperties']} */}
      {/* > */}
      {/*   <AdditionalProperties source="offerProperties" /> */}
      {/* </ResourceTab> */}

      <ResourceTab
        label="catalogue.pages.products.tabs.linkedOffers"
        subscribedFields={['offers']}
      >
        <ProductOffers source="offers" validate={productOffersValidation} />
      </ResourceTab>
    </ResourceTabbedForm>
  );
};

const validateCategoryId = [required()];

const productOffersValidation: Validator[] = [
  (value: CoreOffer[], values: CoreProduct) => {
    const { status } = values;

    if (status !== 'active') {
      return;
    }

    if (!value.length) {
      return 'catalogue.pages.products.messages.linkedOfferRequired';
    }
  },
];
