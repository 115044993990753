import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import {
  GetListParams,
  GetListResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ResourceRoutes } from '../../../resourceRoutes';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';

const finalOrderAlertCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.finalOrderAlert
);

const FinalOrderAlertController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.final_order_alert.resourcePath,
    list: ListPage,
  },
  menuItem: null,
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return finalOrderAlertCrud.list(params);
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await finalOrderAlertCrud.uploadFile_(
          ReplenishmentApiResource.finalOrderAlertLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      }

      return { data: 'error' };
    },
  },
});

export default FinalOrderAlertController;
