import { styled } from '@mui/material/styles';

const PREFIX = 'Dropzone';

export const DropzoneClassNames = {
  label: `${PREFIX}__label`,
  dialogActionLabel: `${PREFIX}__dialogActionLabel`,
  input: `${PREFIX}__input`,
  highlightZone: `${PREFIX}__highlightZone`,
  rulesWrapper: `${PREFIX}__rulesWrapper`,
  preview: `${PREFIX}__preview`,
  previewItem: `${PREFIX}__previewItem`,
  errorText: `${PREFIX}__errorText`,
  highlighted: `${PREFIX}__highlighted`,
  hasError: `${PREFIX}__hasError`,
};

export const Root = styled('label', {
  name: PREFIX,
  overridesResolver: (_, styles) => styles.root,
})`
  position: relative;
  display: block;
  padding: 16px;
  cursor: pointer;
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px dashed ${({ theme }) => theme.palette.grey.A400};
  background-color: ${({ theme }) => theme.palette.background.paper};
  transition: border 0.5s ${({ theme }) => theme.transitions.easing.sharp}};

  &.${DropzoneClassNames.highlighted} {
    border-color: ${({ theme }) => theme.palette.primary.main};
  }

  &.${DropzoneClassNames.hasError}:not(.${DropzoneClassNames.highlighted}) {
    border-color: ${({ theme }) => theme.palette.error.main};
  }

  .MuiTypography-paragraph.${DropzoneClassNames.label} {
    position: relative;
    font-size: 0.75em;
    margin-bottom: 0.2em;
    z-index: 3;
  }

  .${DropzoneClassNames.dialogActionLabel} {
    position: relative;
    padding-block: 52px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    z-index: 3;
  }

  .${DropzoneClassNames.input} {
    position: absolute;
    width: 0;
    height: 0;
    opacity: 0;
    z-index: 2;
  }

  .${DropzoneClassNames.highlightZone} {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.palette.background.paper};
    border-radius: 8px;
    z-index: 4;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    gap: 16px;
    
    .MuiTypography-body2,
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }

  .${DropzoneClassNames.rulesWrapper} {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;

    .MuiTypography-caption {
      color: ${({ theme }) => theme.palette.text.disabled};
    }
  }
  
  .${DropzoneClassNames.preview} {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    margin-block: 24px;
  }
  
  .${DropzoneClassNames.previewItem} {
    position: relative;
    
    .MuiIconButton-root {
      position: absolute;
      right: -8px;
      top: -8px;
      opacity: 0;
      transition: opacity 0.2s ${({ theme }) =>
        theme.transitions.easing.easeOut};
    }
    
    &:hover .MuiIconButton-root {
      opacity: 1;
    }
    
    .MuiSvgIcon-root {
      color: ${({ theme }) => theme.palette.error.main};
    }
  }
  
  .MuiTypography-paragraph.${DropzoneClassNames.errorText} {
    color: ${({ theme }) => theme.palette.error.main};
    margin-top: 16px;
    margin-bottom: 0;
  }
`;
