import { ImageGallery } from '@Plugins/Catalogue/resources/share/Tabs/ImageGallary/ImageGallery';
import { useController } from 'react-hook-form';

const ImagesTab = () => {
  const {
    field: { value: status },
  } = useController({ name: 'status' });

  return (
    <ImageGallery
      type="products"
      mainImageSource="previewImage"
      imagesSource="images"
      isRequired={status === 'active'}
    />
  );
};

export default ImagesTab;
