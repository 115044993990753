import {
  useState,
  DragEvent,
  ChangeEvent,
  useMemo,
  SyntheticEvent,
} from 'react';
import { useTranslate, RecordContextProvider } from 'react-admin';
import { Typography, Fade, useTheme } from '@mui/material';
import { UploadFile, FileOpen } from '@mui/icons-material';

import { uuidv4, classnames } from '@Helpers';
import { MB } from '@MainConstants';
import { formatBytes } from '@UI/FileImport/utils';

import {
  DropzoneProps,
  ErrorCode,
  FileError,
  LocalFile,
  RejectFile,
  TemporaryFile,
} from './interfaces';
import { Root, DropzoneClassNames } from './styled';
import { transformAcceptTypes } from './utils';
import FilePreview from './FilePreview';

const Dropzone = (props: DropzoneProps) => {
  const {
    id,
    label,
    dialogActionLabel = DEFAULT_DIALOG_ACTION_LABELS,
    allowedTypes = ['.png', '.jpeg', '.jpg', '.xlsx', '.xls', '.csv'],
    multiple = false,
    maxSize = 2 * MB,
    maxFileRows,
    aspectRatio,
    previewItems,
    onFilesDrop,
    className,
    children,
    validateFileRemoval,
    onRemove,
    error,
  } = props;

  const translate = useTranslate();
  const theme = useTheme();

  const [isHighlighted, setIsHighlighted] = useState<boolean>(false);

  const identifier = useMemo(() => {
    if (!id) {
      return `zone-input-${uuidv4()}`;
    }

    return id;
  }, [id]);

  const fileAccept = transformAcceptTypes(allowedTypes);

  const onDragEnter = (e: DragEvent) => {
    e.stopPropagation();
    e.preventDefault();

    if (!isHighlighted) {
      setIsHighlighted(true);
    }
  };

  const onDragExit = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isHighlighted) {
      setIsHighlighted(false);
    }
  };

  const onDrop = (e: DragEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isHighlighted) {
      setIsHighlighted(false);
    }

    if (!e.dataTransfer.files.length) {
      return;
    }

    processFiles(Array.from(e.dataTransfer.files));
  };

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      return;
    }

    processFiles(Array.from(e.target.files));
    e.target.value = '';
  };

  const processFiles = (files: File[]) => {
    const validFiles: LocalFile[] = [];
    const rejectedFiles: RejectFile[] = [];

    for (const file of files) {
      const errors: FileError[] = [];

      if (!fileAccept.includes(file.type)) {
        errors.push({
          message: translate(`dropzone.errors.${ErrorCode.FileInvalidType}`, {
            types: allowedTypes.join(', '),
          }),
          code: ErrorCode.FileInvalidType,
        });
      }

      if (file.size > maxSize) {
        errors.push({
          message: translate(`dropzone.errors.${ErrorCode.FileTooLarge}`, {
            size: formatBytes(maxSize),
          }),
          code: ErrorCode.FileTooLarge,
        });
      }

      if (errors.length) {
        rejectedFiles.push({
          file,
          errors,
        });
        continue;
      }

      validFiles.push({
        isRemote: false,
        file,
        url: URL.createObjectURL(file),
        title: file.name,
      });
    }

    onFilesDrop?.(validFiles, rejectedFiles);
  };

  const handleRemove =
    (file: TemporaryFile, idx: number) => async (e: SyntheticEvent) => {
      e.preventDefault();
      e.stopPropagation();

      if (validateFileRemoval) {
        try {
          await validateFileRemoval(file);
        } catch (e) {
          // eslint-disable-next-line
          console.error(e);

          return;
        }
      }

      onRemove?.(file, idx);
    };

  const isPreviewVisible = Boolean(
    previewItems && previewItems.length > 0 && children
  );

  return (
    <Root
      className={classnames(
        className,
        isHighlighted && DropzoneClassNames.highlighted,
        !!error && DropzoneClassNames.hasError
      )}
      htmlFor={identifier}
      onDragEnter={onDragEnter}
    >
      <Fade in={isHighlighted}>
        <div
          className={DropzoneClassNames.highlightZone}
          onDragEnter={onDragEnter}
          onDragOver={onDragEnter}
          onDragLeave={onDragExit}
          onDrop={onDrop}
        >
          <FileOpen fontSize="large" />
          <Typography variant="body2">
            {translate('dropzone.dropHere')}
          </Typography>
        </div>
      </Fade>
      {label && (
        <Typography
          className={DropzoneClassNames.label}
          variant="body1"
          paragraph
          color={error ? theme.palette.error.main : undefined}
        >
          {translate(label)}
        </Typography>
      )}
      {dialogActionLabel && !isPreviewVisible && (
        <div className={DropzoneClassNames.dialogActionLabel}>
          <UploadFile fontSize="large" />
          <Typography variant="subtitle1">
            {translate(
              multiple ? dialogActionLabel.several : dialogActionLabel.single
            )}
          </Typography>
        </div>
      )}
      {isPreviewVisible && (
        <div className={DropzoneClassNames.preview}>
          {previewItems?.map((tempFile, idx) => (
            <FilePreview
              key={tempFile.url}
              file={tempFile}
              onRemove={handleRemove(tempFile, idx)}
            >
              <RecordContextProvider value={tempFile}>
                {children}
              </RecordContextProvider>
            </FilePreview>
          ))}
        </div>
      )}
      <input
        id={identifier}
        type="file"
        accept={fileAccept.join(',')}
        className={DropzoneClassNames.input}
        multiple={multiple}
        onChange={handleInputChange}
      />
      <div className={DropzoneClassNames.rulesWrapper}>
        {allowedTypes?.length > 0 && (
          <Typography variant="caption">
            {translate('dropzone.allowedTypes', {
              types: allowedTypes.map((item) => item.slice(1)).join(', '),
            })}
          </Typography>
        )}
        {aspectRatio && (
          <Typography variant="caption">
            {translate('dropzone.aspectRatio', {
              ratio: aspectRatio,
            })}
          </Typography>
        )}
        {maxSize && (
          <Typography variant="caption">
            {translate('dropzone.maxSize', {
              size: formatBytes(maxSize),
            })}
          </Typography>
        )}
        {maxFileRows && (
          <Typography variant="caption">
            {translate('dropzone.maxRows', {
              rows: maxFileRows,
            })}
          </Typography>
        )}
      </div>
      {error && (
        <Typography
          className={DropzoneClassNames.errorText}
          variant="caption"
          paragraph
        >
          {error}
        </Typography>
      )}
    </Root>
  );
};

const DEFAULT_DIALOG_ACTION_LABELS = {
  single: 'ra.input.file.upload_single',
  several: 'ra.input.file.upload_several',
};

export default Dropzone;
