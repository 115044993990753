import { Validator } from 'react-admin';

import {
  ValidationImageValue,
  AspectRationValidationMessages,
} from './interfaces';
import { getAspectRatio, getImageNaturalWidthHeight } from './utils';

export const validateAspectRatio =
  (targetRatio: number, messages?: AspectRationValidationMessages): Validator =>
  async (value: ValidationImageValue | string | null) => {
    if (!value) {
      return;
    }

    const {
      ratioError = 'ra.validation.invalidAspectRatio',
      landscapeError = 'ra.validation.landscapeRequired',
      imageError = 'ra.validation.imageLoadError',
    } = messages || {};

    const src = typeof value === 'string' ? value : value.url;

    let width = 0;
    let height = 0;

    try {
      const naturalSize = await getImageNaturalWidthHeight(src);

      width = naturalSize.width;
      height = naturalSize.height;
    } catch (error) {
      return imageError;
    }

    if (height >= width) {
      return landscapeError;
    }

    const { ratio } = getAspectRatio(width, height);

    const targetRatioFormatted = Math.floor(Number(targetRatio.toFixed(2)));

    if (targetRatioFormatted !== Math.floor(ratio)) {
      const targetHeight = Math.round(width / targetRatio);

      return { message: ratioError, args: { width, height: targetHeight } };
    }

    return undefined;
  };
