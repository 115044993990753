import { useState, useCallback, useRef, createContext } from 'react';
import { useTranslate } from 'react-admin';

import { ConfirmAdultPackageDialog } from '../ConfirmAdultPackageDialog';
import { Typography } from '@mui/material';

import {
  ContextDefaultValue,
  CCOrderConfirmContextProviderProps,
  ResolveRejectRefObject,
  OpenConfirmationDialogOptions,
} from './interfaces';

export const CCOrderConfirmContext =
  createContext<Nullable<ContextDefaultValue>>(null);

const CCOrderConfirmContextProvider = ({
  children,
}: CCOrderConfirmContextProviderProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [titleState, setTitle] = useState<string>(DEFAULT_TITLE);
  const [content, setContent] = useState<string>('');
  const [confirmBtnText, setConfirmBtnText] = useState<string | undefined>();
  const translate = useTranslate();

  const promiseResolveRejectRef =
    useRef<Nullable<ResolveRejectRefObject>>(null);

  const openConfirmationDialog = useCallback(
    (args: string | OpenConfirmationDialogOptions) =>
      new Promise((resolve, reject) => {
        const options: OpenConfirmationDialogOptions =
          typeof args === 'string' ? { content: args } : args;

        setContent(options.content);

        if (options.title) {
          setTitle(options.title);
        } else if (titleState !== DEFAULT_TITLE) {
          setTitle(DEFAULT_TITLE);
        }

        if (options.confirmBtnText) {
          setConfirmBtnText(options.confirmBtnText);
        } else if (confirmBtnText) {
          setConfirmBtnText(undefined);
        }

        setOpen(true);

        promiseResolveRejectRef.current = { resolve, reject };
      }),
    []
  );

  const onClose = () => {
    setOpen(false);
  };

  const handleConfirmAction = () => {
    if (!promiseResolveRejectRef.current) {
      return;
    }

    promiseResolveRejectRef.current.resolve();
    onClose();
  };

  const handleDecline = () => {
    onClose();

    if (!promiseResolveRejectRef.current) {
      return;
    }

    promiseResolveRejectRef.current.reject();
  };

  const renderContent = () => {
    const translatedContent = translate(content);

    if (translatedContent.includes('\n')) {
      return (
        <>
          {translatedContent.split('\n').map((line) => (
            <Typography key={line} variant="subtitle1">
              {line}
            </Typography>
          ))}
        </>
      );
    }

    return translatedContent;
  };

  return (
    <CCOrderConfirmContext.Provider value={{ openConfirmationDialog, onClose }}>
      {children}

      <ConfirmAdultPackageDialog
        open={open}
        title={titleState}
        onClose={onClose}
        onConfirm={handleConfirmAction}
        onDecline={handleDecline}
        confirmBtnText={confirmBtnText}
      >
        {renderContent()}
      </ConfirmAdultPackageDialog>
    </CCOrderConfirmContext.Provider>
  );
};

const DEFAULT_TITLE =
  'wms-picking.pages.click-collect.messages.confirmAdultTitle';

export default CCOrderConfirmContextProvider;
