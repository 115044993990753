const classnames = (
  ...classes: (string | Record<string, boolean> | undefined | null | boolean)[]
): string =>
  classes
    .map((item) => {
      if (typeof item === 'object' && item !== null) {
        return Object.entries(item).reduce<string[]>(
          (memo, [className, condition]) => {
            if (!condition) {
              return memo;
            }

            return [...memo, className];
          },
          []
        );
      }

      return item;
    })
    .flat()
    .filter(Boolean)
    .join(' ');

export default classnames;
