import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetListResult,
  GetListParams,
  DeleteResult,
  UpdateParams,
  UpdateResult,
} from 'react-admin';
import { ReplenishmentApiResource } from '../../apiRoutes';
import { ReplenishmentCrud } from '../../crud';

const futureDeliveriesCrud = new ReplenishmentCrud(
  ReplenishmentApiResource.futureDeliveries
);

const FutureDeliveriesController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.futureDeliveries.resourcePath,
    list: ListPage,
  },
  menuItem: null,
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return futureDeliveriesCrud.list(params);
    },
    delete: async (resource, params): Promise<DeleteResult> => {
      await futureDeliveriesCrud.delete(params);

      return { data: params.previousData };
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await futureDeliveriesCrud.uploadFile_(
          ReplenishmentApiResource.futureDeliveriesLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      } else {
        await futureDeliveriesCrud.update(params);

        return {
          data: params.data,
        };
      }
    },
  },
});

export default FutureDeliveriesController;
