import type { ReactNode, SyntheticEvent } from 'react';

export interface FileInfo {
  url: string;
  title: string;
}

export interface LocalFile extends FileInfo {
  isRemote: false;
  file: File;
}

export interface RemoteFile extends FileInfo {
  isRemote: true;
}

export enum ErrorCode {
  FileInvalidType = 'file-invalid-type',
  FileTooLarge = 'file-too-large',
  FileTooSmall = 'file-too-small',
  TooManyFiles = 'too-many-files',
}

export interface FileError {
  message: string;
  code: ErrorCode | string;
}

export interface RejectFile {
  errors: FileError[];
  file: File;
}

export type TemporaryFile = LocalFile | RemoteFile;

export type AllowedType = `.${string}`;

export interface DropzoneActionLabel {
  single: string;
  several: string;
}

export interface DropzoneProps {
  id?: string;
  label?: string;
  className?: string;
  allowedTypes?: AllowedType[];
  maxSize?: number;
  maxFileRows?: number;
  aspectRatio?: string;
  onFilesDrop?: (files: LocalFile[], rejectedFiles: RejectFile[]) => void;
  dialogActionLabel?: DropzoneActionLabel;
  previewItems?: TemporaryFile[];
  multiple?: boolean;
  children?: ReactNode;
  validateFileRemoval?: (file: TemporaryFile) => boolean | Promise<boolean>;
  onRemove?: (file: TemporaryFile, idx: number) => void;
  error?: string;
}

export interface FilePreviewProps {
  file: TemporaryFile;
  onRemove?: (e: SyntheticEvent) => void;
  children?: ReactNode;
}
