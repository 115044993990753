import { useEffect, useState, useMemo } from 'react';
import { Admin, CustomRoutes, Resource, I18nProvider } from 'react-admin';
import { Route, PathRouteProps } from 'react-router-dom';
import { QueryClient } from 'react-query';
import { DndProvider } from 'react-dnd';
import MultiBackend from 'react-dnd-multi-backend';
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch';
import { BackendFactory } from 'dnd-core';

import { ROUTES } from '@CoreRoutes';
import pluginManager from '@PluginManager/PluginManager';
import { ErrorBoundaryProvider, useSetErrorToErrorsContext } from '@Helpers';
import { PLUGIN_LIST } from '@ROOT/helpers/AccessPermissions/constants';

import authProvider from './providers/authProvider/authProvider';
import { Layout } from './layout';
import LocaleProvider from './layout/localeProvider';
import { Login, Dashboard, Configuration } from './pages';
import { darkTheme } from '@ROOT/layout/themes';

const App = () => {
  const [i18nProvider, setI18nProvider] =
    useState<Nullable<I18nProvider>>(null);
  const { errors, onErrorHandler } = useSetErrorToErrorsContext();
  const queryClient = new QueryClient({
    defaultOptions: {
      mutations: {
        onError: (error) => onErrorHandler(error),
      },
      queries: {
        onError: (error) => onErrorHandler(error),
      },
    },
  });
  const customRoutes = useMemo(() => {
    const routes: { withLayout: PathRouteProps[]; noLayout: PathRouteProps[] } =
      { withLayout: [], noLayout: [] };

    pluginManager.customRoutes.forEach((rout) => {
      if (!rout.noLayout) {
        routes.withLayout.push(rout);
      } else {
        routes.noLayout.push(rout);
      }
    });

    return routes;
  }, [pluginManager.customRoutes.length]);

  useEffect(() => {
    pluginManager.init(PLUGIN_LIST);
    setI18nProvider(pluginManager.i18nProvider);
  }, []);

  if (!pluginManager.dataProvider || !i18nProvider) return null;

  return (
    <DndProvider
      // Moved DND provider to top APP file in order to prevent backend multiplying on the page
      backend={MultiBackend as unknown as BackendFactory}
      options={HTML5toTouch}
    >
      <ErrorBoundaryProvider.Provider
        value={{ errors, updateContextErrors: onErrorHandler }}
      >
        <LocaleProvider>
          <Admin
            title=""
            queryClient={queryClient}
            dataProvider={pluginManager.dataProvider}
            authProvider={authProvider}
            dashboard={Dashboard}
            loginPage={Login}
            layout={Layout}
            i18nProvider={i18nProvider}
            disableTelemetry
            theme={darkTheme}
            requireAuth
          >
            <CustomRoutes>
              {customRoutes.withLayout.map((routeConfig) => (
                <Route {...routeConfig} key={routeConfig.path} />
              ))}

              <Route
                path={ROUTES.configuration.route}
                element={<Configuration />}
              />
            </CustomRoutes>

            <CustomRoutes noLayout>
              {customRoutes.noLayout.map((routeConfig) => (
                <Route {...routeConfig} key={routeConfig.path} />
              ))}
            </CustomRoutes>

            {pluginManager.resourceRoutes.map((routeConfig) => (
              <Resource {...routeConfig} key={routeConfig.name} />
            ))}
          </Admin>
        </LocaleProvider>
      </ErrorBoundaryProvider.Provider>
    </DndProvider>
  );
};

export default App;
