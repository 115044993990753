import styled from 'styled-components';

import { ResourceDropzone } from '@UI/ResourceUI/ResourceDropzone';
import { DropzoneClassNames } from '@UI/Dropzone/styled';

export const StyledDropzone = styled(ResourceDropzone)`
  min-height: 303px;
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .${DropzoneClassNames.highlightZone} {
    padding: 24px;
  }
`;
