const API_URL: Record<string, string> = {
  production: 'https://replenish.bi.tools.jiffygrocery.co.uk/v1',
  development: 'https://replenish-dev.bi.tools.jiffygrocer.oc.uk/v1',
  staging: 'https://api2-stage.jiffy-team.com/replenishment/v1',
};

export const envUrl = process.env.REACT_APP_REPLENISH_API_URL;
export const REPLENISHMENT_API =
  envUrl || API_URL[process.env.REACT_APP_ENV || 'development'];

export const ReplenishmentApiResource = {
  products: `${REPLENISHMENT_API}/products`,
  stores: `${REPLENISHMENT_API}/stores`,
  categories: `${REPLENISHMENT_API}/categories`,
  clusterRules: `${REPLENISHMENT_API}/cluster_rules`,
  futureDeliveries: `${REPLENISHMENT_API}/future_deliveries`,
  lots: `${REPLENISHMENT_API}/lots`,
  simply_replenish: `${REPLENISHMENT_API}/simply_replenish`,
  simplyExport: `${REPLENISHMENT_API}/simply_replenish/export`,
  simplyRecalculate: `${REPLENISHMENT_API}/simply_replenish/recalculate`,
  sales: `${REPLENISHMENT_API}/sales`,
  stocks: `${REPLENISHMENT_API}/stocks`,
  suppliers: `${REPLENISHMENT_API}/suppliers`,
  schedules: `${REPLENISHMENT_API}/schedules`,
  productMatrix: `${REPLENISHMENT_API}/product_matrix`,
  finalOrderAlert: `${REPLENISHMENT_API}/alerts/final_order`,
  alert: `${REPLENISHMENT_API}/replenishment`,
  replenishment: `${REPLENISHMENT_API}/replenishment`,
  replenishmentCalculate: `${REPLENISHMENT_API}/replenishment/calculate`,
  replenishmentExport: `${REPLENISHMENT_API}/replenishment/export`,
  replenishmentSyncs: `${REPLENISHMENT_API}/syncs`,
  // Loaders
  productsLoad: `${REPLENISHMENT_API}/loaders/products`,
  finalOrderAlertLoad: `${REPLENISHMENT_API}/loaders/final_order_alerts`,
  storesLoad: `${REPLENISHMENT_API}/loaders/stores`,
  futureDeliveriesLoad: `${REPLENISHMENT_API}/loaders/future_deliveris`,
  lotsLoad: `${REPLENISHMENT_API}/loaders/lots`,
  salesLoad: `${REPLENISHMENT_API}/loaders/sales`,
  stocksLoad: `${REPLENISHMENT_API}/loaders/stocks`,
  productMatrixLoad: `${REPLENISHMENT_API}/loaders/matrix`,
  supplierScheduleLoad: `${REPLENISHMENT_API}/loaders/schedules`,
  simply_replenishLoad: `${REPLENISHMENT_API}/loaders/simply_replenish`,
};
