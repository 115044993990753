import { useState } from 'react';

import { Flex } from '@UI';
import { S3Uploader, useSetErrorToErrorsContext } from '@Helpers';
import { ACCEPTED_IMAGE_TYPES } from '@ROOT/constants';

import { ImageUploaderDndWrapper } from './ImageUploader/ImageUploaderDndWrapper';
import { ImageDropItem } from './ImageUploader/interface';
import { ImageGalleryProps } from './interface';

export const ImageGalleryWidget = ({
  type,
  mainImageUrl,
  mainImageError,
  mainImageKey = 'imageGallery.image',
  additionalImagesUrls = [],
  additionalImageError,
  updateMainImage,
  updateAdditionalImages,
  hideAdditionalImage,
  hideMainImage,
  allowedTypes = ACCEPTED_IMAGE_TYPES,
}: ImageGalleryProps) => {
  const { onErrorHandler } = useSetErrorToErrorsContext();
  const [fieldLoading, setFieldLoading] = useState<'main' | 'additional'>();
  const { types, sizeMB } = allowedTypes;

  async function onAddMainImage(file: File) {
    if (!file) {
      return;
    }

    try {
      setFieldLoading('main');
      const uploader = new S3Uploader([file], type);
      const [fileUrl] = await uploader.send();

      updateMainImage?.(fileUrl);
    } catch (e) {
      onErrorHandler(e);
    }

    setFieldLoading(undefined);
  }

  function onRemoveMainImage() {
    updateMainImage?.('');
  }

  async function onAdditionalImageUploaded(file: File) {
    try {
      setFieldLoading('additional');
      const uploader = new S3Uploader([file], type);
      const [fileUrl] = await uploader.send();

      updateAdditionalImages?.([...additionalImagesUrls, fileUrl]);
    } catch (e) {
      onErrorHandler(e);
    }

    setFieldLoading(undefined);
  }

  function onRemoveAdditionalImage(value: string) {
    updateAdditionalImages?.(
      additionalImagesUrls.filter((image) => image !== value)
    );
  }

  function updateImageField(source: string, value?: string) {
    const [field, index] = source.split('-');

    if (field === 'image') {
      updateMainImage?.(value);
    } else {
      additionalImagesUrls[parseInt(index ?? '0')] = value ?? '';
      updateAdditionalImages?.([...additionalImagesUrls]);
    }
  }

  function onSortImagesHandler(
    currentImage: ImageDropItem,
    droppedImage: ImageDropItem
  ) {
    const { source: currentSource, value: currentValue } = currentImage;
    const { source: droppedSource, value: droppedValue } = droppedImage;

    updateImageField(currentSource, droppedValue);
    updateImageField(droppedSource, currentValue);
  }

  return (
    <Flex gap={2} flexWrap="wrap" asColumn={false}>
      {!hideMainImage && (
        <Flex sx={{ position: 'relative', m: 0 }}>
          <ImageUploaderDndWrapper
            label={mainImageKey}
            maxSizeMb={sizeMB}
            accept={types}
            isLoading={fieldLoading === 'main'}
            isDraggable
            onDrop={onSortImagesHandler}
            value={mainImageUrl}
            onUpload={onAddMainImage}
            onRemove={onRemoveMainImage}
            source="image"
            error={mainImageError}
          />
        </Flex>
      )}

      {!hideAdditionalImage &&
        additionalImagesUrls?.map((image, index: number) => (
          <Flex key={image} gap={2} sx={{ m: 0 }}>
            <ImageUploaderDndWrapper
              label="imageGallery.additionalImage"
              maxSizeMb={sizeMB}
              accept={types}
              onDrop={onSortImagesHandler}
              isDraggable
              value={image}
              onUpload={onAdditionalImageUploaded}
              onRemove={onRemoveAdditionalImage}
              source={`images-${index}`}
            />
          </Flex>
        ))}

      {!hideAdditionalImage && (
        <Flex gap={2} sx={{ m: 0 }}>
          <ImageUploaderDndWrapper
            label="imageGallery.additionalImage"
            maxSizeMb={sizeMB}
            accept={types}
            isLoading={fieldLoading === 'additional'}
            onUpload={onAdditionalImageUploaded}
            source={`images-${mainImageUrl?.length ?? 0}`}
            error={additionalImageError}
          />
        </Flex>
      )}
    </Flex>
  );
};
