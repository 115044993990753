import { TranslationNamespace } from '@Helpers';
import { TRANSLATION_SCHEMA } from './schemas/translationSchema';
import { PackageDeliveryType } from '@Plugins/WMSPicking/resources/ClickAndCollect/pages/interface';

export const clickAndCollectTranslationSchema = new TranslationNamespace<
  typeof TRANSLATION_SCHEMA
>('wms-picking.pages.click-collect', TRANSLATION_SCHEMA);

export const EXCLUDE_PACKAGE_API_URL =
  '/orders/v2/orders/update/excludeItemsByBag';

export const DELIVERY_TYPE_NAME: Record<PackageDeliveryType, string> = {
  [PackageDeliveryType.Frozen]: 'Frozen',
  [PackageDeliveryType.OwnKitchen]: 'OwnKitchen',
  [PackageDeliveryType.Alcohol]: 'Alcohol',
  [PackageDeliveryType.Tobacco]: 'Alcohol',
  [PackageDeliveryType.Grocery]: 'Grocery',
  [PackageDeliveryType.Pharma]: 'Grocery',
  [PackageDeliveryType.Chemical]: 'Grocery',
};
