import { CopyEvent } from './utils';

const copyToClipboard = async (text: string, html?: string): Promise<void> => {
  if (!navigator?.clipboard?.write) {
    document.addEventListener(
      'copy',
      (event) => {
        event.preventDefault();

        if (html) {
          event.clipboardData?.setData('text/html', html);
        }

        event.clipboardData?.setData('text/plain', text);
      },
      { once: true }
    );

    document.execCommand('copy');

    return;
  }

  const clipboardItemData: Record<string, Blob> = {
    'text/plain': new Blob([text], { type: 'text/plain' }),
  };

  if (html) {
    clipboardItemData['text/html'] = new Blob([html], { type: 'text/html' });
  }

  const clipboardItem: ClipboardItem = new ClipboardItem(clipboardItemData);

  try {
    await navigator.clipboard.write([clipboardItem]);
    const event = new CopyEvent(clipboardItem);

    navigator.clipboard.dispatchEvent(event);
  } catch (error) {
    // eslint-disable-next-line
    console.error('Failed to copy to clipboard:', error);
  }
};

export default copyToClipboard;
