import { ChangeEvent, MouseEvent } from 'react';
import {
  DateField,
  useNotify,
  useRecordContext,
  useTranslate,
} from 'react-admin';
import {
  Box,
  Button,
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';

import { Flex, Loader } from '@UI';
import { ResourceRoutes } from '@PluginManager/plugins/resourceRoutes';
import { MoneyFormatter, unitsToPoints } from '@Helpers';
import { KYCVerificationStatus } from '@Plugins/Customers/resources/Customers/interfaces';
import { handleInput } from '@UI/ResourceUI/ResourceMoney/utils';

import { RESOURCES_SCHEMA } from '../schemas/resourcesSchema';
import { ClickCollectOrder } from '../interfaces';
import {
  clickAndCollectTranslationSchema,
  DELIVERY_TYPE_NAME,
} from '../constants';
import { useCCOrderConfirmContext } from '../components/Confirmations';
import useCCDetailPage from '../hooks/useCCDetailPage';

import { PackageDeliveryType } from './interface';
import { HighlightingRowInfoBlock, StyledAlert } from './styles';

export const DetailsPage = () => {
  const record = useRecordContext<ClickCollectOrder>();
  const translate = useTranslate();
  const notify = useNotify();

  const {
    amountPrompt,
    orderTotal,
    selectedPackages,
    isPackagesConfirmedForCashOrder,
    isLoading,
    isExcluding,
    errorMessages,
    validationMessage,
    packagesAreExcluded,
    updateDelivery,
    onErrorHandler,
    setSelectedPackages,
    setIsPackagesConfirmedForCashOrder,
    setAmountPrompt,
    excludePackagesFromOrder,
  } = useCCDetailPage();
  const { openConfirmationDialog } = useCCOrderConfirmContext();

  const labels = clickAndCollectTranslationSchema.labels;
  const isCash = record?.payment_method === 'CASH';

  const handlerSubmit = (data: number[]) => {
    const identifier = record.uuid || record.id;

    updateDelivery(
      ResourceRoutes.wmsClickAndCollect.resourcePath,
      {
        id: identifier,
        data,
        previousData: { id: identifier },
      },
      {
        mutationMode: 'optimistic',
        onSuccess: () => {
          if (!data.length) {
            notify(
              translate(
                clickAndCollectTranslationSchema.messages.successfullyCanceled
              ),
              { type: 'success', autoHideDuration: 10000 }
            );

            return;
          }

          notify(
            translate(
              clickAndCollectTranslationSchema.messages.updateDelivery,
              {
                orderId: record.order_id,
                status: translate(
                  `wms-picking.pages.click-collect.lists.statuses.${
                    packagesAreExcluded ? 'partially_delivered' : 'delivered'
                  }`
                ),
              }
            ),
            { type: 'success', autoHideDuration: 10000 }
          );
        },
        onError: onErrorHandler,
      }
    );
  };

  const calculateOrderChange = (): string => {
    if (!amountPrompt || isNaN(Number(amountPrompt)) || !orderTotal) {
      return '0';
    }

    const amountNum = Number(unitsToPoints(amountPrompt));

    if (amountNum < orderTotal) {
      return '0';
    }

    const change = amountNum - orderTotal;
    const formater = new MoneyFormatter(change);

    return formater.unitsAmount;
  };

  const handleConfirmExcludePackages = async () => {
    if (!record) {
      return;
    }

    const excluded =
      record?.bag_types?.filter((item) => !selectedPackages.includes(item)) ??
      [];
    const hasAdultPackage = selectedPackages.includes(
      PackageDeliveryType.Alcohol
    );

    try {
      if (!excluded.length) {
        await openConfirmationDialog(
          `wms-picking.pages.click-collect.messages.${
            hasAdultPackage ? 'confirmAdultMessage' : 'confirmUnchangedPackages'
          }`
        );
        setIsPackagesConfirmedForCashOrder(true);

        return;
      }

      await openConfirmationDialog(
        'wms-picking.pages.click-collect.messages.confirmExclude'
      );

      excludePackagesFromOrder({
        id: `${record.uuid}`,
        data: { bag_types: excluded },
      });
    } catch {
      // Confirm dialog declined. Do nothing.
    }
  };

  const mapPackages = (type: PackageDeliveryType) => {
    const name = translate(
      `wms-picking.pages.click-collect.lists.deliveryTypeName.${DELIVERY_TYPE_NAME[type]}`
    );
    const isSelected = selectedPackages.includes(type);

    return (
      <HighlightingRowInfoBlock
        $selected={isSelected}
        key={name}
        fullWidth
        title={
          <Flex
            asColumn={false}
            alignItems="center"
            justifyContent="space-between"
            fullWidth
            sx={{ borderBottom: '1px solid #2F3841' }}
          >
            <Typography variant="subtitle1">{name}</Typography>

            <Checkbox
              checked={isSelected}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedPackages((prev) => [...prev, type]);

                  return;
                }

                setSelectedPackages((prev) =>
                  prev.filter((item) => item !== type)
                );
              }}
              disabled={isCash && isPackagesConfirmedForCashOrder}
            />
          </Flex>
        }
        gap={2}
      >
        {type === PackageDeliveryType.Alcohol && (
          <StyledAlert
            variant="filled"
            severity="error"
            iconMapping={{
              error: <Chip label="18 +" color="error" />,
            }}
          >
            <Typography variant="caption" component="p">
              {translate('wms-picking.pages.click-collect.messages.adult1')}
            </Typography>
            <Typography variant="caption" component="p">
              {translate('wms-picking.pages.click-collect.messages.adult2')}
            </Typography>
          </StyledAlert>
        )}
      </HighlightingRowInfoBlock>
    );
  };

  const handleConfirmHandover = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      await openConfirmationDialog(
        `wms-picking.pages.click-collect.messages.${
          selectedPackages.includes(PackageDeliveryType.Alcohol)
            ? 'confirmAdultMessage'
            : 'confirmFinish'
        }`
      );

      handlerSubmit(selectedPackages);
    } catch {
      // Confirm dialog declined. Do nothing.
    }
  };

  const handleFailHandover = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    try {
      await openConfirmationDialog({
        content: 'wms-picking.pages.click-collect.messages.confirmFailMessage',
        confirmBtnText: 'wms-picking.pages.click-collect.buttons.fail',
      });

      handlerSubmit([]);
    } catch {
      // Confirm dialog declined. Do nothing.
    }
  };

  const renderBody = () => {
    if (errorMessages.length > 0) {
      return (
        <Typography
          bgcolor="rgba(244, 67, 54, 0.08)"
          color="error"
          fontWeight="bold"
          align="center"
          padding={2}
          fontSize={14}
          marginTop={2}
        >
          {errorMessages.map((error, index) => (
            <Box key={index} marginY={1}>
              {error}
            </Box>
          ))}
        </Typography>
      );
    }

    const isConfirmingPackages = !isPackagesConfirmedForCashOrder && isCash;

    const confirmAction = isConfirmingPackages
      ? handleConfirmExcludePackages
      : handleConfirmHandover;
    const confirmActionDisabled = isConfirmingPackages
      ? isExcluding
      : isLoading;

    return (
      <>
        <Flex
          asColumn={false}
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ p: 2 }}
          gap={2}
        >
          <Box sx={{ width: 215, pl: 1 }}>
            <Typography variant="caption">
              {translate(labels.buyerName)}
            </Typography>
            <Typography fontSize={14}>
              {record?.recipient?.full_name || translate(labels.unknown)}
            </Typography>
          </Box>
          <Box sx={{ width: 215, pl: 1 }}>
            <Typography variant="caption">
              {translate('wms-picking.pages.click-collect.labels.age')}
            </Typography>
            <Typography fontSize={14}>
              {translate(
                record?.customer?.[0]?.kyc_verification_status ===
                  KYCVerificationStatus.VERIFIED
                  ? 'wms-picking.pages.click-collect.labels.confirmed'
                  : 'wms-picking.pages.click-collect.labels.unconfirmed'
              )}
            </Typography>
          </Box>
          <Box sx={{ width: 215, pl: 1 }}>
            <Typography variant="caption">
              {translate('wms-picking.pages.click-collect.labels.comments')}
            </Typography>
            <Typography fontSize={14}>
              {record?.address?.comment || '-'}
            </Typography>
          </Box>
          <Box sx={{ width: 215, pl: 1 }}>
            <Typography variant="caption">
              {translate('wms-picking.pages.click-collect.labels.paymentType')}
            </Typography>
            <Typography fontSize={14}>
              {translate(
                `wms-picking.pages.click-collect.lists.paymentMethods.${record.payment_method}`
              )}
            </Typography>
          </Box>
        </Flex>

        {record.bag_types?.map(mapPackages)}

        {isCash && isPackagesConfirmedForCashOrder && (
          <Flex
            asColumn={false}
            justifyContent="space-between"
            flexWrap="wrap"
            sx={{ p: 2 }}
            gap={2}
          >
            <Box sx={{ width: '100%', pl: 1.5 }}>
              <Typography variant="caption">
                {translate(
                  'wms-picking.pages.click-collect.labels.amountToPay'
                )}
              </Typography>
              <Typography fontSize={14}>
                {new MoneyFormatter(orderTotal).withCurrency}
              </Typography>
            </Box>

            <TextField
              label={translate(
                'wms-picking.pages.click-collect.labels.enterAmount'
              )}
              value={amountPrompt}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                setAmountPrompt(e.target.value)
              }
              variant="outlined"
              InputProps={{
                onInput: handleInput,
                startAdornment: (
                  <InputAdornment position="start">
                    {MoneyFormatter.getCurrencySymbol()}
                  </InputAdornment>
                ),
              }}
              error={!!validationMessage}
              helperText={validationMessage}
              sx={{ width: 215 }}
            />
            <Box sx={{ width: 215, pl: 1 }}>
              <Typography variant="caption">
                {translate('wms-picking.pages.click-collect.labels.autoChange')}
              </Typography>
              <Typography fontSize={14}>{calculateOrderChange()}</Typography>
            </Box>
          </Flex>
        )}

        <Flex
          asColumn={false}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mt: 2, px: 2 }}
        >
          <Button
            type="button"
            color="primary"
            variant="contained"
            disabled={!selectedPackages.length || confirmActionDisabled}
            onClick={confirmAction}
          >
            {translate('wms-picking.pages.click-collect.buttons.confirm')}
          </Button>
          <Button
            type="button"
            color="error"
            variant="contained"
            disabled={isLoading}
            onClick={handleFailHandover}
          >
            {translate('wms-picking.pages.click-collect.buttons.fail')}
          </Button>
        </Flex>
      </>
    );
  };

  if (!record) return <Loader />;

  return (
    <Flex asColumn maxWidth={500} width="95vw" height="100vh" padding={1}>
      <Box padding={1}>
        <Typography variant="h5">
          {translate(labels.order, { orderId: record.order_id })}
        </Typography>

        <Typography variant="subtitle2">
          {`${translate(labels.createdAt)}: `}

          <DateField source={RESOURCES_SCHEMA.createdAt} showTime />
        </Typography>
      </Box>

      {renderBody()}
    </Flex>
  );
};
