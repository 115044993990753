import { useEffect, useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useTranslate,
  useUpdate,
} from 'react-admin';
import { useNavigate } from 'react-router';
import { useMutation } from 'react-query';

import {
  pointsToUnits,
  unitsToPoints,
  useSetErrorToErrorsContext,
} from '@Helpers';
import { clickAndCollectTranslationSchema } from '@Plugins/WMSPicking/resources/ClickAndCollect/constants';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { PackageDeliveryType } from '@Plugins/WMSPicking/resources/ClickAndCollect/pages/interface';
import { ClickCollectOrder } from '@Plugins/WMSPicking/resources/ClickAndCollect/interfaces';
import { excludePackagesRequest } from '@Plugins/WMSPicking/resources/ClickAndCollect/pages/utils';

export const useCCDetailPage = () => {
  const record = useRecordContext<ClickCollectOrder>();
  const translate = useTranslate();
  const notify = useNotify();
  const navigate = useNavigate();

  const { errors, onErrorHandler } = useSetErrorToErrorsContext();

  const [isPackagesConfirmedForCashOrder, setIsPackagesConfirmedForCashOrder] =
    useState<boolean>(false);
  const [orderTotal, setOrderTotal] = useState<number>(0);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [validationMessage, setValidationMessage] = useState<string>('');
  const [amountPrompt, setAmountPrompt] = useState<string>('');
  const [selectedPackages, setSelectedPackages] = useState<
    PackageDeliveryType[]
  >([]);
  const [packagesAreExcluded, setPackagesAreExcluded] =
    useState<boolean>(false);

  const [updateDelivery, { isSuccess, isLoading }] = useUpdate();
  const { mutate: excludePackagesFromOrder, isLoading: isExcluding } =
    useMutation({
      mutationKey: 'excludePackagesRequest',
      mutationFn: excludePackagesRequest,
      onSuccess: ({ actual_paid_price }) => {
        setOrderTotal(actual_paid_price);
        setPackagesAreExcluded(true);
        setIsPackagesConfirmedForCashOrder(true);
      },
    });

  const handleValidateAmountReceived = (val: string) => {
    if (!val) {
      setValidationMessage('');

      return;
    }

    if (isNaN(Number(val))) {
      setValidationMessage(translate('ra.validation.number'));

      return;
    }

    if (orderTotal && Number(unitsToPoints(Number(val))) < orderTotal) {
      setValidationMessage(
        translate('ra.validation.minValue', {
          min: pointsToUnits(orderTotal),
        })
      );

      return;
    }

    setValidationMessage('');
  };

  useEffect(() => {
    if (!record) return;

    if (record.payment_method === 'CASH' && !orderTotal) {
      setOrderTotal(record.actual_paid_price);
    }

    const isCanceled = ['cancelled', 'failed'].includes(record.public_status);
    const isDelivered = record.public_status === 'delivered';
    const errors: string[] = [];

    if (isCanceled) {
      errors.push(translate(clickAndCollectTranslationSchema.errors.canceled));
    }

    if (isDelivered) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.alreadyPicked)
      );
    }

    if (
      !isCanceled &&
      !isDelivered &&
      record.public_status !== 'ready_for_pickup'
    ) {
      errors.push(
        translate(clickAndCollectTranslationSchema.errors.incorrectStatus)
      );
    }

    if (record.delivery_method !== 'CLICK_AND_COLLECT') {
      errors.push(
        translate(
          clickAndCollectTranslationSchema.errors.incorrectDeliveryMethod
        )
      );
    }

    setErrorMessages(errors);
  }, [record?.status, record?.delivery_method]);

  useEffect(() => {
    if (!isSuccess) return;
    navigate(ResourceRoutes.wmsClickAndCollect.resourcePath);
  }, [isSuccess]);

  useEffect(() => {
    const resultErrors = Object.values(errors).flat();

    if (resultErrors.length) {
      const errorsText = [
        translate(clickAndCollectTranslationSchema.errors.cantUpdateDelivery),
        ...resultErrors,
      ].join('\n');

      notify(errorsText, {
        type: 'error',
        autoHideDuration: 10000,
        multiLine: true,
      });
    }
  }, [JSON.stringify(errors)]);

  useEffect(() => {
    if (record) {
      if (record.customer_cash && !amountPrompt) {
        setAmountPrompt(pointsToUnits(record.customer_cash));
      }

      if (record.payment_method === 'CASH') {
        handleValidateAmountReceived(amountPrompt);
      }
    }
  }, [record?.customer_cash, amountPrompt]);

  return {
    orderTotal,
    amountPrompt,
    selectedPackages,
    errorMessages,
    isLoading,
    isExcluding,
    validationMessage,
    isPackagesConfirmedForCashOrder,
    excludePackagesFromOrder,
    packagesAreExcluded,
    setSelectedPackages,
    setOrderTotal,
    updateDelivery,
    onErrorHandler,
    setAmountPrompt,
    setIsPackagesConfirmedForCashOrder,
  };
};

export default useCCDetailPage;
