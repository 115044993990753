import { useContext } from 'react';

import { CCOrderConfirmContext } from './provider';

export const useCCOrderConfirmContext = () => {
  const value = useContext(CCOrderConfirmContext);

  if (!value) {
    throw new Error(
      'To use confirmation modals it should be under CCOrderConfirmContext.Provider'
    );
  }

  return value;
};
