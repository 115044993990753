import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslate } from 'react-admin';
import { Box } from '@mui/material';

import { MB } from '@MainConstants';

import { ImageUploaderView } from './ImageUploaderView';
import { ImageDropItem, ImageUploaderProps } from './interface';

export const ImageUploaderDndWrapper = ({
  source,
  onUpload,
  value,
  onRemove,
  isDraggable,
  onDrop,
  isLoading,
  accept,
  maxSizeMb = 2,
  label,
  error,
}: ImageUploaderProps) => {
  const translate = useTranslate();

  const [{ isDragging }, drag] = useDrag({
    type: 'IMAGE',
    collect: (monitor: { isDragging: () => boolean }) => ({
      isDragging: monitor.isDragging(),
    }),
    item: {
      value,
      source,
    },
  });
  const BoxRef = useRef();

  const [{ handlerId }, drop] = useDrop({
    accept: 'IMAGE',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop: (item) => {
      onDrop?.(
        {
          value,
          source,
        },
        item as ImageDropItem
      );
    },
  });

  drag(drop(BoxRef));

  const rejectMessage = translate('imageGallery.allowedImage', {
    types: accept?.join(', '),
    size: maxSizeMb,
  });

  return (
    <Box
      data-handler-id={handlerId}
      ref={isDraggable ? BoxRef : undefined}
      sx={{
        opacity: isDragging ? 0.5 : 1,
        width: '100%',
        padding: 0,
      }}
    >
      <ImageUploaderView
        label={label}
        rejectFileMessage={rejectMessage}
        maxSizeMb={maxSizeMb ? maxSizeMb * MB : undefined}
        accept={accept}
        onUpload={onUpload}
        source={source}
        value={value}
        isLoading={isLoading}
        onRemove={onRemove}
        error={error}
      />
    </Box>
  );
};
