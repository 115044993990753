import { useGetList, useNotify } from 'react-admin';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import { RequestAPI } from '@RestApi';
import { ResourceRoutes } from '@Plugins/resourceRoutes';
import { ReplenishmentApiResource } from '@Plugins/Replenishment/apiRoutes';

import {
  CALCULATION_LAST_JOB_ID_KEY,
  CALCULATION_POLLING_TIME,
} from '../constants';
import { Predict, SyncData } from '../interface';

const useCalculateJob = () => {
  const notify = useNotify();
  const { refetch, data: listData = [] } = useGetList<Predict>(
    ResourceRoutes.replenishment.calculate.resourcePath,
    {
      sort: { field: 'updated_at', order: 'ASC' },
    }
  );

  const [lastJobId, setLastJobId] = useState<Nullable<string>>(() =>
    localStorage.getItem(CALCULATION_LAST_JOB_ID_KEY)
  );
  const [isJobProcessing, setIsJobProcessing] = useState<boolean>(false);
  const [lastSyncData, setLastSyncData] = useState<SyncData | undefined>();

  const { data: lastJobCheckData } = useQuery({
    queryKey: ['checkJobStatus', lastJobId],
    queryFn: async () => {
      try {
        return await RequestAPI.get(
          `${ReplenishmentApiResource.replenishmentCalculate}/${lastJobId}`
        );
      } catch (error) {
        return { data: { task_status: 'TIMEOUT', task_id: lastJobId } };
      }
    },
    enabled: !!lastJobId,
    onSuccess: (response) => {
      const { task_status, task_id } = response.data;

      if (task_id !== lastJobId) {
        return;
      }

      if (task_status === 'SUCCESS') {
        setIsJobProcessing(false);
        notify('replenishment.pages.calculate.messages.jobFinished', {
          type: 'success',
        });
        localStorage.removeItem(CALCULATION_LAST_JOB_ID_KEY);
        refetchSyncData();
        refetch();
      }

      if (task_status === 'FAILURE') {
        setIsJobProcessing(false);
        notify('replenishment.pages.calculate.messages.jobFailed', {
          type: 'error',
        });
        localStorage.removeItem(CALCULATION_LAST_JOB_ID_KEY);
      }
    },
    retry: (failureCount) => {
      const shouldRetry = failureCount < 20;

      if (!shouldRetry) {
        setIsJobProcessing(false);
        notify('replenishment.pages.calculate.messages.jobFailed', {
          type: 'error',
        });
        localStorage.removeItem(CALCULATION_LAST_JOB_ID_KEY);
      }

      return shouldRetry;
    },
    refetchInterval: (response) => {
      if (response) {
        const { task_status, task_id } = response.data;

        if (task_id !== lastJobId) {
          return false;
        }

        if (
          ['PENDING', 'PROGRESS', 'STARTED', 'RETRY', 'TIMEOUT'].includes(
            task_status
          )
        ) {
          return CALCULATION_POLLING_TIME;
        }

        return false;
      }

      return lastJobId ? CALCULATION_POLLING_TIME : false;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    cacheTime: 0,
  });

  const { refetch: refetchSyncData } = useQuery({
    queryKey: 'replenishment.Sync',
    queryFn: async () => {
      const response = await RequestAPI.get(
        `${ReplenishmentApiResource.replenishmentSyncs}`
      );

      const { data } = response as { data: SyncData[] };

      return data;
    },
    onSuccess: (response) => {
      const sorted = response.sort((a, b) => {
        const dateA = new Date(a.last_synced_entry_date);
        const dateB = new Date(b.last_synced_entry_date);

        return dateB.getTime() - dateA.getTime();
      });

      setLastSyncData(sorted[0]);
    },
    cacheTime: 0,
    retry: false,
  });

  const { mutate: calculate, isLoading: isCalculating } = useMutation({
    mutationKey: 'calculateSuppliers',
    mutationFn: async () => {
      const response = await RequestAPI.post(
        ReplenishmentApiResource.replenishmentCalculate,
        { suppliers: [] }
      );

      const { data: jobId } = response as { data: string };

      return jobId;
    },
    onSuccess: (jobId) => {
      notify('replenishment.pages.calculate.messages.successCalculate', {
        type: 'success',
      });

      localStorage.setItem(CALCULATION_LAST_JOB_ID_KEY, jobId);
      setLastJobId(jobId);
    },
    onError: () => {
      notify(
        'replenishment.pages.calculate.messages.calculateJobCreationFailed',
        {
          type: 'error',
        }
      );
    },
  });

  useEffect(() => {
    if (lastJobId && !lastJobCheckData) {
      setIsJobProcessing(true);
    }
  }, [lastJobId]);

  return {
    lastJobId,
    lastSyncData,
    isJobProcessing,
    lastJobCheckData,
    calculate,
    isCalculating,
    listData,
  };
};

export default useCalculateJob;
