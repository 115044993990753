import { PluginResourceController } from '@PluginBase';
import { ListPage } from './ListPage';
import { ResourceRoutes } from '../../../resourceRoutes';
import {
  GetOneParams,
  GetListResult,
  GetListParams,
  DeleteResult,
  DeleteParams,
  UpdateResult,
  UpdateParams,
  GetOneResult,
  CreateResult,
  CreateParams,
} from 'react-admin';

import { ReplenishmentApiResource } from '../../apiRoutes';
import { ResourceItemPage } from '@Widgets/ResourceItemPage/ResourceItemPage';
import { ReplenishmentCrud } from '../../crud';
import { PageForm } from './PageForm';

const productCrud = new ReplenishmentCrud(ReplenishmentApiResource.products);

interface Product {
  id: string;
  name: string;
}

const ProductsController = new PluginResourceController({
  resourceRoute: {
    name: ResourceRoutes.replenishment.products.resourcePath,
    list: ListPage,
    create: (
      <ResourceItemPage redirect="list" type="create">
        <PageForm />
      </ResourceItemPage>
    ),
    edit: (
      <ResourceItemPage redirect="list" type="edit">
        <PageForm />
      </ResourceItemPage>
    ),
  },
  menuItem: null,
  dataProvider: {
    getList: (
      resource: string,
      params: GetListParams
    ): Promise<GetListResult> => {
      return productCrud.list(params);
    },
    getOne: async (resource, params: GetOneParams): Promise<GetOneResult> => {
      const result = await productCrud.getOne<{ data: Product }>(params);

      return {
        data: result?.data,
      };
    },
    create: async (resource, params: CreateParams): Promise<CreateResult> => {
      const result = await productCrud.create<{ data: Product }>(params);

      return {
        data: result?.data,
      };
    },
    delete: async (resource, params: DeleteParams): Promise<DeleteResult> => {
      await productCrud.delete(params);

      return { data: params.previousData };
    },
    update: async (
      resource: string,
      params: UpdateParams
    ): Promise<UpdateResult> => {
      if (params.meta === 'load') {
        await productCrud.uploadFile_(
          ReplenishmentApiResource.productsLoad,
          params.data.file
        );

        return { data: { id: 1 } };
      } else {
        await productCrud.update(params);

        return {
          data: params.data,
        };
      }
    },
  },
});

export default ProductsController;
