import {
  BaseSyntheticEvent,
  FormEvent,
  useState,
  useRef,
  useEffect,
  ClipboardEvent,
} from 'react';
import { useTranslate, TextInput, TimeInput, useNotify } from 'react-admin';
import { FieldValues, FormProvider, useForm } from 'react-hook-form';
import { Button, Tooltip } from '@mui/material';
import {
  Help,
  CopyAll,
  Save,
  Delete,
  Done,
  ContentPaste,
} from '@mui/icons-material';
import moment from 'moment';

import { useIsMobile } from '@ROOT/hooks';
import {
  CreateModeToolbarStyled,
  SlotToolbarClasses,
} from '@Widgets/DeliverySchedule/components/ScheduleIntervalAccordion/styled';
import { Flex, ResourceMoneyInput, RowInfoBlock } from '@UI';
import { TIME_DISPLAY_FORMAT } from '@Widgets/Schedule/DayWorkInterval/constants';
import { copyToClipboard, CopyEvent } from '@Helpers/CopyToClipboard';

import { validationSchema } from './validation';
import {
  DeliveryScheduleSlotFormFields,
  FormValueInstance,
  ScheduleIntervalFormProps,
} from './interfaces';
import { normalizeMaxFutureDate } from './utils';

const sxWhiteClock = {
  '& input[type="time"]::-webkit-calendar-picker-indicator': {
    filter: 'invert(100%)',
  },
};

const ScheduleIntervalForm = (
  props: ScheduleIntervalFormProps
): JSX.Element => {
  const {
    onSubmit,
    onClose,
    record,
    closeButtonText = 'ra.action.cancel',
    isLoading = false,
    localization = {},
    timeInputFormat = TIME_DISPLAY_FORMAT,
    createForm = false,
  } = props;
  const isMobile = useIsMobile();
  const t = useTranslate();
  const notify = useNotify();

  const {
    name = 'name',
    leadTime = 'leadTime',
    startTime = 'startTime',
    deliveryPrice = 'deliveryPrice',
    maxFutureDate = 'maxFutureDate',
    cutOffTime = 'cutOffTime',
    whProcessingDuration = 'whProcessingDuration',
    endTime = 'endTime',
    deliveryCapacity = 'deliveryCapacity',
    freeDeliveryThreshold = 'freeDeliveryThreshold',
    slotDeliveryMinOrderValue = 'slotDeliveryMinOrderValue',
    slotDeliveryMinOrderHint = 'slotDeliveryMinOrderHint',
  } = localization;

  const [copied, setCopied] = useState<boolean>(false);
  const [copiedContent, setCopiedContent] = useState<string | undefined>();
  const copiedTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>();

  const formMethods = useForm<
    FormValueInstance<DeliveryScheduleSlotFormFields>
  >({
    values: normalizeMaxFutureDate(record),
    mode: 'onChange',
    reValidateMode: 'onChange',
  });
  const { isDirty } = formMethods.formState;

  const onValidSubmit = (values: FieldValues, e?: BaseSyntheticEvent) => {
    e?.preventDefault();

    onSubmit?.(values);
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    formMethods.handleSubmit(onValidSubmit)();
  };

  const handleParse = (value: string): string =>
    moment(value, TIME_DISPLAY_FORMAT, true).format(timeInputFormat);

  const clearCopiedTimeout = () => {
    if (copiedTimeoutRef.current) {
      clearTimeout(copiedTimeoutRef.current);
      copiedTimeoutRef.current = undefined;
    }
  };

  const handleCopyFormValues = async () => {
    if (copied) {
      setCopied(false);
    }

    clearCopiedTimeout();

    const {
      name,
      cut_off_time,
      delivery_end_time,
      delivery_start_time,
      delivery_price,
      delivery_capacity,
      free_delivery_threshold,
      max_future_date,
      order_amount_threshold,
      wh_processing_duration_sec,
      delivery_lead_duration_sec,
    } = formMethods.getValues();

    const values = JSON.stringify({
      name,
      cut_off_time,
      delivery_end_time,
      delivery_start_time,
      delivery_price,
      delivery_capacity,
      free_delivery_threshold,
      max_future_date,
      order_amount_threshold,
      wh_processing_duration_sec,
      delivery_lead_duration_sec,
    });

    try {
      await copyToClipboard(`slotValues(${values})`);
      setCopied(true);

      copiedTimeoutRef.current = setTimeout(() => {
        setCopied(false);
      }, 3000);
      notify('stores.warehouses.pages.phrases.copySuccess', {
        type: 'success',
      });
    } catch (e) {
      notify('stores.warehouses.pages.errors.failedToCopy', {
        type: 'error',
      });
    }
  };

  const populateSlotFromString = (json: string) => {
    let parsed: Nullable<Record<string, unknown>> = null;

    try {
      parsed = JSON.parse(json);
    } catch (e) {
      // eslint-disable-next-line
      console.error('Failed to parse copied values', e);
    }

    if (!parsed) {
      return;
    }

    for (const [field, value] of Object.entries(parsed)) {
      formMethods.setValue(
        field as keyof FormValueInstance<DeliveryScheduleSlotFormFields>,
        value as Nullable<string>,
        { shouldDirty: true }
      );
    }

    setCopiedContent(undefined);
    navigator.clipboard.writeText('');
  };

  const handlePasteFormValues = () => {
    if (!copiedContent) {
      return;
    }

    populateSlotFromString(copiedContent);
  };

  const handleInputPaste = (e: ClipboardEvent<HTMLInputElement>) => {
    const textInBuffer = e.clipboardData?.getData('text/plain');

    if (!textInBuffer) {
      return;
    }

    const match = textInBuffer.match(SLOT_COPIED_VALUES_REGEX);

    if (!match) {
      return;
    }

    e.preventDefault();
    populateSlotFromString(match[1]);
  };

  useEffect(() => {
    const permanentClipboardCheck = async () => {
      if (!navigator?.clipboard?.readText) {
        return;
      }

      const copiedText = await navigator.clipboard.readText();
      const matches = copiedText.match(SLOT_COPIED_VALUES_REGEX);

      if (!matches) {
        return;
      }

      setCopiedContent(matches[1]);
    };
    const listener = async (e: CopyEvent) => {
      if (!e.clipboardItem?.getType) {
        return;
      }

      const plainTextBlob = await e.clipboardItem.getType('text/plain');
      const copiedContent = await plainTextBlob.text();
      const matches = copiedContent.match(SLOT_COPIED_VALUES_REGEX);

      if (!matches) {
        return;
      }

      setCopiedContent(matches[1]);
    };

    if (navigator?.clipboard) {
      permanentClipboardCheck();
      navigator.clipboard.addEventListener('cmscopy', listener);

      return () => {
        navigator.clipboard.removeEventListener('cmscopy', listener);
      };
    }
  }, [copiedContent, createForm]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit}>
        <Flex asColumn={isMobile} gap={1} fullWidth>
          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TextInput
              name="name"
              source="name"
              fullWidth
              disabled={isLoading}
              label={t(name)}
              validate={validationSchema.name}
              onPaste={handleInputPaste}
            />

            <TextInput
              name="delivery_lead_duration_sec"
              source="delivery_lead_duration_sec"
              fullWidth
              disabled={isLoading}
              label={t(leadTime)}
              validate={validationSchema.delivery_lead_duration_sec}
              onBlur={() => {
                formMethods.setFocus('cut_off_time');
                formMethods.trigger(['cut_off_time']);
              }}
              onPaste={handleInputPaste}
            />

            <TimeInput
              name="delivery_start_time"
              source="delivery_start_time"
              validate={validationSchema.delivery_start_time}
              label={t(startTime)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              onBlur={() => {
                formMethods.trigger(['delivery_end_time', 'cut_off_time']);
              }}
              onPaste={handleInputPaste}
            />

            <ResourceMoneyInput
              name="delivery_price"
              source="delivery_price"
              label={t(deliveryPrice)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.delivery_price}
              onPaste={handleInputPaste}
            />

            <TextInput
              name="max_future_date"
              source="max_future_date"
              label={t(maxFutureDate)}
              onPaste={handleInputPaste}
              fullWidth
              disabled
            />

            <ResourceMoneyInput
              name="order_amount_threshold"
              source="order_amount_threshold"
              label={t(slotDeliveryMinOrderValue)}
              fullWidth
              validate={validationSchema.order_amount_threshold}
              disabled={isLoading}
              postfixSymbol={
                <Tooltip placement="top" title={t(slotDeliveryMinOrderHint)}>
                  <Help fontSize="small" sx={{ cursor: 'pointer' }} />
                </Tooltip>
              }
              onPaste={handleInputPaste}
            />
          </RowInfoBlock>

          <RowInfoBlock asColumn flex={1} justifyContent="flex-start">
            <TimeInput
              name="cut_off_time"
              source="cut_off_time"
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              label={t(cutOffTime)}
              validate={validationSchema.cut_off_time}
              fullWidth
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              onPaste={handleInputPaste}
            />

            <TextInput
              name="wh_processing_duration_sec"
              source="wh_processing_duration_sec"
              fullWidth
              disabled={isLoading}
              label={t(whProcessingDuration)}
              validate={validationSchema.wh_processing_duration_sec}
              onBlur={() => {
                formMethods.setFocus('cut_off_time');
                formMethods.trigger(['cut_off_time']);
              }}
              onPaste={handleInputPaste}
            />

            <TimeInput
              name="delivery_end_time"
              source="delivery_end_time"
              validate={validationSchema.delivery_end_time}
              label={t(endTime)}
              fullWidth
              InputLabelProps={{ shrink: true }}
              sx={sxWhiteClock}
              disabled={isLoading}
              parse={handleParse}
              inputProps={{
                pattern: '[0-2][0-9]:[0-5][0-9]',
              }}
              onBlur={() => {
                formMethods.trigger(['delivery_start_time']);
              }}
              onPaste={handleInputPaste}
            />

            <TextInput
              name="delivery_capacity"
              source="delivery_capacity"
              label={t(deliveryCapacity)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.delivery_capacity}
              onPaste={handleInputPaste}
            />

            <ResourceMoneyInput
              name="free_delivery_threshold"
              source="free_delivery_threshold"
              label={t(freeDeliveryThreshold)}
              fullWidth
              disabled={isLoading}
              validate={validationSchema.free_delivery_threshold}
              onPaste={handleInputPaste}
            />
          </RowInfoBlock>
        </Flex>

        <CreateModeToolbarStyled>
          <div className={SlotToolbarClasses.leftCorner}>
            <Button
              type="button"
              variant="contained"
              size="medium"
              color="success"
              disabled={isLoading || !isDirty}
              onClick={handleSubmit}
              startIcon={<Save />}
            >
              {t('ra.action.save')}
            </Button>
            {!createForm && (
              <Button
                type="button"
                variant="text"
                size="medium"
                color="warning"
                startIcon={copied ? <Done /> : <CopyAll />}
                onClick={handleCopyFormValues}
              >
                {t(
                  copied
                    ? 'stores.warehouses.pages.labels.copied'
                    : 'ra.action.copy'
                )}
              </Button>
            )}
            {createForm && copiedContent && (
              <Button
                type="button"
                variant="text"
                size="medium"
                color="warning"
                startIcon={<ContentPaste />}
                onClick={handlePasteFormValues}
              >
                {t('ra.action.paste')}
              </Button>
            )}
          </div>
          <Button
            type="button"
            variant="text"
            color="error"
            size="medium"
            onClick={onClose}
            startIcon={<Delete />}
          >
            {t(closeButtonText)}
          </Button>
        </CreateModeToolbarStyled>
      </form>
    </FormProvider>
  );
};

const SLOT_COPIED_VALUES_REGEX = /^slotValues\((.*?)\)$/;

export default ScheduleIntervalForm;
