import { Typography } from '@mui/material';
import { useLocaleState, useTranslate } from 'react-admin';

import { Flex } from '@UI';

import { LastSyncDatesProps } from './interfaces';

const LastSyncDates = ({ lastSyncData, calculationDate }: LastSyncDatesProps) => {
  const translate = useTranslate();
  const [locale] = useLocaleState();

  return (
    <Flex asColumn sx={{ pt: 3 }}>
      {lastSyncData && (
        <Typography variant="caption" fontSize="small">
          {translate('replenishment.pages.calculate.labels.lastSyncDate', {
            date: formatDate(lastSyncData, locale),
          })}
        </Typography>
      )}
      {calculationDate && (
        <Typography variant="caption" fontSize="small">
          {translate('replenishment.pages.calculate.labels.syncUpdatedAt', {
            date: formatDate(calculationDate, locale),
          })}
        </Typography>
      )}
    </Flex>
  );
};

const formatDate = (dateStr: string, locale: string) => {
  const date = new Date(dateStr);

  return toLocaleStringSupportsLocales
    ? date.toLocaleString(locale, {})
    : date.toLocaleString();
};

const toLocaleStringSupportsLocales = (() => {
  // from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleString
  try {
    new Date().toLocaleString('i');
  } catch (error) {
    return error instanceof RangeError;
  }

  return false;
})();

export default LastSyncDates;
